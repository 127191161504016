@keyframes spinner-element {
  0% {
    opacity: 1;
    backface-visibility: hidden;
    transform: translateZ(0) scale(1, 0.01);
  }
  100% {
    opacity: 0;
    backface-visibility: hidden;
    transform: translateZ(0) scale(1, 1);
  }
}
.spinner-element {
  /* width: 100%;
  height: 100%; */
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
}
.spinner-element div {
  /* box-sizing: content-box; */
}
.spinner-element div > div {
  position: absolute;
  width: 28.42px;
  height: 28.42px;
  border-radius: 50%;
  background: #7cc0a0;
  animation: spinner-element 1.75s linear infinite;
}
.spinner-element div:nth-child(1) > div {
  left: 101.5px;
  top: 87.5px;
  animation-delay: -1.5s;
}
.spinner-element > div:nth-child(1) {
  transform: rotate(0deg);
  transform-origin: 115.71px 101.71px;
}
.spinner-element div:nth-child(2) > div {
  left: 96.5px;
  top: 98.5px;
  animation-delay: -1.25s;
}
.spinner-element > div:nth-child(2) {
  transform: rotate(51.43deg);
  transform-origin: 110.71px 112.71px;
}
.spinner-element div:nth-child(3) > div {
  left: 84.5px;
  top: 101.5px;
  animation-delay: -1s;
}
.spinner-element > div:nth-child(3) {
  transform: rotate(102.86deg);
  transform-origin: 98.71px 115.71px;
}
.spinner-element div:nth-child(4) > div {
  left: 74.5px;
  top: 93.5px;
  animation-delay: -0.75s;
}
.spinner-element > div:nth-child(4) {
  transform: rotate(154.29deg);
  transform-origin: 88.71px 107.71px;
}
.spinner-element div:nth-child(5) > div {
  left: 74.5px;
  top: 81.5px;
  animation-delay: -0.5s;
}
.spinner-element > div:nth-child(5) {
  transform: rotate(205.71deg);
  transform-origin: 88.71px 95.71px;
}
.spinner-element div:nth-child(6) > div {
  left: 84.5px;
  top: 73.5px;
  animation-delay: -0.25s;
}
.spinner-element > div:nth-child(6) {
  transform: rotate(257.14deg);
  transform-origin: 98.71px 87.71px;
}
.spinner-element div:nth-child(7) > div {
  left: 96.5px;
  top: 76.5px;
  animation-delay: 0s;
}
.spinner-element > div:nth-child(7) {
  transform: rotate(308.57deg);
  transform-origin: 110.71px 90.71px;
}
.spinner-container {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: transparent;
}
